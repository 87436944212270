"use client";

import { useAuth } from "@clerk/nextjs";
import { Dialog } from "@headlessui/react";
import { Button } from "@holdenmatt/shadcn-kit";
import { Menu, X } from "lucide-react";
import { useState } from "react";

import { SIGNED_IN_HOME_URL } from "@/constants";

import { Container } from "../atoms/Container";
import { Logo } from "../atoms/Logo";
import { LogoType } from "../atoms/LogoType";

const navigation = [
  //{ name: "Product", href: "#" },
  { name: "Pricing", href: "/pricing" },
  { name: "About", href: "/about" },
];

export const Header = () => {
  const { isLoaded, userId } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <Container size="lg" className="relative px-0 sm:px-0">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="lg:flex-1">
            <a href="/" className="flex items-center gap-2">
              <Logo size={64} className="h-12" />
              <LogoType className="text-2xl" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden gap-3 lg:flex lg:flex-1 lg:justify-end">
            {isLoaded &&
              (userId ? (
                <Button variant="outline" asChild>
                  <a href={SIGNED_IN_HOME_URL}>Open app</a>
                </Button>
              ) : (
                <>
                  <Button variant="ghost" className="text-base" asChild>
                    <a href="/sign-in">Sign in</a>
                  </Button>
                  <Button variant="outline" className="text-base text-indigo-500" asChild>
                    <a href="/sign-up">Sign up</a>
                  </Button>
                </>
              ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Logo size={64} className="h-12" />
                <LogoType className="text-2xl" />
              </div>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  {isLoaded &&
                    (userId ? (
                      <a
                        href="/projects/recent"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Open app
                      </a>
                    ) : (
                      <>
                        <a
                          href="/sign-in"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Sign in
                        </a>
                        <a
                          href="/sign-up"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Sign up
                        </a>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </Container>
  );
};
