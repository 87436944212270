import { cn } from "@holdenmatt/shadcn-kit";
import Image from "next/image";

export const Logo = ({ size = 32, className }: { size?: number; className?: string }) => (
  <Image
    width={size}
    height={size}
    className={cn("-ml-1.5 mr-2 h-8 w-auto", className)}
    src="/logo/logo-128x128.png"
    alt="ChartPilot logo"
  />
);
